export default {
  breadcrumb: {
    id: 'breadcrumb_aria_label',
    defaultMessage: 'Breadcrumb',
  },
  myOrders: {
    id: 'straight_refund_confirmation_page_breadcrumb_my_orders',
    defaultMessage: 'My orders',
  },
  refundRequest: {
    id: 'resolution_flow_return_confirmation_page_breadcrumb_refund_request',
    defaultMessage: 'Refund request',
  },
}
