export default {
  description: {
    id: 'resolution_flow_cancel_confirmation_page_description',
    defaultMessage:
      'If you’ve been charged, we’ll process your full refund within 3 business days. It may take longer to appear in your account depending on your payment method and bank.',
  },
  goToMyOrdersCta: {
    id: 'resolution_flow_cancel_confirmation_page_cta_go_to_my_orders',
    defaultMessage: 'Go to Orders',
  },
  subTitle: {
    id: 'resolution_flow_cancel_confirmation_page_info_title',
    defaultMessage: 'Refund requested',
  },
  tip: {
    id: 'resolution_flow_cancel_confirmation_page_tip',
    defaultMessage:
      'If your seller has already shipped your item, contact Back Market Customer Care to get a free return label.',
  },
  title: {
    id: 'resolution_flow_cancel_confirmation_page_title',
    defaultMessage: 'Your order is canceled.',
  },
}
